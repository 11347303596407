import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import memoizeOne from 'memoize-one';
import Router from 'next/router';
import { Component, ComponentType } from 'react';
import {
  connectRefinementList,
  Index,
  InstantSearch,
} from 'react-instantsearch-dom';
import urlParse from 'url-parse';

import { AlgoliaSSRContext } from './getAlgoliaServerState';
import {
  getDefaultSortOption,
  formatUrl,
  searchStateToQueryParams,
  SortOption,
  urlToSearchState,
} from './utils';
import getConfig from 'config/config';

import { trackAlgoliaSearch } from 'lib/analytics';
import AlgoliaContext from 'lib/context/AlgoliaContext';
import { isBrowser } from 'lib/utils/browser';

import {
  AlgoliaSearchResult,
  InstantSearchProps,
  SearchStateProps,
} from './types';

type WithAlgoliaState = {
  defaultSort?: SortOption;
  previousUrl?: string;
  searchState?: Partial<SearchStateProps>;
};

export const SEARCHABLE_NAVIGATIONS_INDEX_NAME = getConfig(
  'algolia.indexNameSearchableNavigations'
) as string;
export const CONTENT_OWNER_INDEX_NAME = getConfig(
  'algolia.indexNameContentOwner'
) as string;
const getDisplayPath = () => {
  if (!isBrowser()) {
    return '';
  }
  return urlParse(document.location.pathname).pathname;
};

export const getPathWithSlug = (currentUrl?: string): string => {
  if (!currentUrl) {
    return '';
  }

  const { pathname } = urlParse(currentUrl);
  return pathname || '';
};

const THROTTLE_TIME = 100;

export default function withAlgolia<TProps>(
  WrappedComponent: ComponentType<TProps>,
  shouldSuppressEmptySearch = false,
  trackSearchStateInUrl = false,
  useMultiIndex = false,
  enableForSSR = false
) {
  return class extends Component<TProps, WithAlgoliaState> {
    static contextType = AlgoliaContext;
    static displayName =
      `withAlgolia(Component, shouldSuppressEmptySearch=${shouldSuppressEmptySearch},` +
      ` trackSearchStateInUrl=${trackSearchStateInUrl}, useMultiIndex=${useMultiIndex}, enableForSSR=${enableForSSR})`;

    static getDerivedStateFromProps(_: TProps, state: WithAlgoliaState) {
      // We don't have easy access to the current URL from server-side renders,
      // so we wait until search is bootstrapped on client to check update state.
      if (!isBrowser() || !trackSearchStateInUrl) {
        return null;
      }
      const { searchState } = state;

      const newUrl = Router.asPath;
      // InstantSearch widget will add extra fields to the searchState.
      const newSearchState = {
        ...searchState,
        ...urlToSearchState(getDefaultSortOption(newUrl), newUrl, false),
      };

      if (state.previousUrl === newUrl) {
        //  Don't update searchState if it is not due to external url change.
        return null;
      }

      return {
        defaultSort: getDefaultSortOption(newUrl),
        previousUrl: newUrl,
        searchState: { ...newSearchState },
      };
    }

    state: WithAlgoliaState = {};

    createUrl = (searchState: SearchStateProps) => {
      const defaultSort = this.state.defaultSort || getDefaultSortOption();
      const displayQueryParams = searchStateToQueryParams(
        searchState,
        defaultSort
      );
      return formatUrl({
        pathname: getDisplayPath(),
        query: {
          ...displayQueryParams,
        },
      });
    };

    searchClient = memoizeOne(algoliaClient => {
      // Memorize proxy search client so that we don't create new search clients on each render.
      return {
        ...algoliaClient,
        search(
          requests: Array<{
            params: {
              query: string;
            };
          }>
        ) {
          if (
            shouldSuppressEmptySearch &&
            requests.every(({ params }) => !params.query)
          ) {
            return Promise.resolve({
              results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
              })),
            });
          }

          const startTime = performance.now();
          const promise: Promise<{
            results: AlgoliaSearchResult[];
          }> = algoliaClient.search(requests);
          return promise.then((result: { results: AlgoliaSearchResult[] }) => {
            let resultCount = 0;
            try {
              for (const { hits } of result?.results) {
                resultCount += hits.length;
              }
            } catch (e) {
              // for parsing errors when result is not in correct format, should not break.
              resultCount = -1;
            }
            trackAlgoliaSearch({
              query: requests[0].params.query,
              responseTimeMs: performance.now() - startTime,
              resultCount,
            });
            return result;
          });
        },
      };
    });

    renderInstantSearch = (
      instantSearchProps: InstantSearchProps,
      index: string[]
    ) => {
      // Ref: https://www.algolia.com/doc/guides/building-search-ui/ui-and-ux-patterns/multi-index-search/react/
      return (
        <InstantSearch {...instantSearchProps} createURL={this.createUrl}>
          <VirtualRefinementList attribute="product.hierarchicalCategories.lvl0" />
          <VirtualRefinementList attribute="product.brand" />
          <VirtualRefinementList attribute="price" />
          <VirtualRefinementList attribute="colorFamilyName" />
          <VirtualRefinementList attribute="product.hierarchicalCategories.lvl2" />
          <WrappedComponent {...this.props} />
          {useMultiIndex &&
            index.map(item => <Index indexName={item} key={item} />)}
        </InstantSearch>
      );
    };

    onSearchStateChange = throttle(
      (newSearchState: SearchStateProps) => {
        const searchState = { ...this.state.searchState, ...newSearchState };
        const defaultSort = this.state.defaultSort || getDefaultSortOption();
        const internalUrlPath = Router.pathname;
        const internalUrlProps = Router.query;
        const internalUrl = {
          pathname: internalUrlPath,
          query: {
            ...internalUrlProps,
          },
        };
        const displayQueryParams = searchStateToQueryParams(
          searchState,
          defaultSort
        );
        const displayUrl = {
          pathname: getDisplayPath(),
          query: {
            ...displayQueryParams,
          },
        };

        // update URL with new filters
        // Updating URL causes the entire app component to re-render which is
        // expensive. Delay updating url here so that we can update the algolia
        // search results first.
        updateUrlDebounced(internalUrl, displayUrl);

        // update searchState in state
        this.setState({
          defaultSort,
          previousUrl: this.state.previousUrl,
          searchState,
        });
      },
      THROTTLE_TIME,
      { trailing: true }
    );

    render() {
      const productIndex = getDefaultSortOption().value;
      const index = useMultiIndex
        ? [
            productIndex,
            SEARCHABLE_NAVIGATIONS_INDEX_NAME,
            CONTENT_OWNER_INDEX_NAME,
          ]
        : [productIndex];

      const { algoliaClient, initialState } = this.context;
      const searchClientProxy = this.searchClient(algoliaClient);

      let instantSearchProps: InstantSearchProps = {
        indexName: productIndex,
        root: { Root: 'span', props: {} },
        searchClient: searchClientProxy,
      };

      if (trackSearchStateInUrl) {
        instantSearchProps = {
          ...instantSearchProps,
          onSearchStateChange: this.onSearchStateChange,
        };
        if (this.state.searchState) {
          instantSearchProps.searchState = this.state.searchState;
        }
      }

      if (enableForSSR) {
        return (
          <AlgoliaSSRContext.Consumer>
            {algoliaSSRContext => {
              if (algoliaSSRContext?.currentUrl) {
                const searchState = urlToSearchState(
                  getDefaultSortOption(algoliaSSRContext.currentUrl),
                  algoliaSSRContext.currentUrl,
                  false
                );
                if (algoliaSSRContext.searchStateUpdater) {
                  algoliaSSRContext.searchStateUpdater(searchState);
                }
                instantSearchProps.searchState = searchState;
              }
              const combinedSearchProps = {
                ...initialState,
                ...instantSearchProps,
                ...algoliaSSRContext,
              };
              return this.renderInstantSearch(combinedSearchProps, index);
            }}
          </AlgoliaSSRContext.Consumer>
        );
      }

      return this.renderInstantSearch(instantSearchProps, index);
    }
  };
}

const VirtualRefinementList = connectRefinementList(() => null);

type Url = {
  pathname: string;
  query: {
    [key: string]: string | string[] | undefined;
  };
};

const updateUrlDebounced = debounce((internalUrl: Url, displayUrl: Url) => {
  Router.replace(internalUrl, displayUrl, {
    shallow: true,
  });
}, 500);
